import { Flex } from "@storyofams/react-ui";
import { createRoot } from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import { Switch, Route, HashRouter } from "react-router-dom";

import { Providers } from "~/components";
import { Container as ToastContainer } from "~/components/Toast/Container";
import { App } from "~/containers";
import { loadMessages } from "~/lib";
import { CSSReset } from "~/styles/CSSReset";
import { ReactPhoneCSS } from "~/styles/ReactPhoneCSS";

import "react-toastify/dist/ReactToastify.min.css";
import { FlowLocale } from "./graphql/api/sdk";
import { polyfill } from "./lib/polyfills";
import { StyleSheetManager } from "styled-components";

const flow = (window as any)?.__INITIAL_STATE__?.flowContainer?.flow;
const locale = flow?.locale;
const translationData = flow?.translation;
const isRtl = locale === FlowLocale.Custom && translationData?.isRtl;

const render = (messages) => {
  const hostElement = document.getElementById("perfect-product-finder");
  if (!hostElement) return;

  const globalReset = document.createElement("style");
  globalReset.innerHTML = `
    /* Lantern CSS Reset */
    html {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      height: 100%;
    }
    body {
      margin: 0;
      height: 100%;
    }
  `;
  document.head.appendChild(globalReset);

  // Create a Shadow DOM root
  const shadowRoot = hostElement.attachShadow({ mode: "open" });

  shadowRoot.addEventListener("click", (event) => {
    console.log("click inside shadowRoot", event);
  });

  // (1) A dedicated div for styled-components’ <style> tags
  const scStylesContainer = document.createElement("div");
  shadowRoot.appendChild(scStylesContainer);

  // (2) Create a container inside Shadow DOM for React to render into
  const shadowContainer = document.createElement("div");
  shadowRoot.appendChild(shadowContainer);

  // Mount React inside Shadow DOM
  createRoot(shadowContainer).render(
    <>
      <StyleSheetManager target={scStylesContainer}>
        <Providers isRtl={isRtl} locale={(locale === FlowLocale.Custom ? translationData?.locale : locale?.toLowerCase()) || "en"} messages={messages}>
          <Flex flexDirection="column" flex="1" dir={isRtl ? "rtl" : undefined}>
            <HashRouter>
              <Switch>
                <Route component={App} />
              </Switch>
            </HashRouter>
            <ToastContainer />
            <CSSReset />
            <ReactQueryDevtools />
          </Flex>
        </Providers>
      </StyleSheetManager>
    </>
  );

  if (flow?.cssEditor) {
    const customStyleEl = document.createElement("style");
    customStyleEl.textContent = flow.cssEditor;
    shadowRoot.appendChild(customStyleEl);
  }

  const phoneInputStyleEl = document.createElement("style");
  phoneInputStyleEl.textContent = ReactPhoneCSS;
  shadowRoot.appendChild(phoneInputStyleEl);
};

const init = async () => {
  let messages;

  if (locale === FlowLocale.Custom) {
    await polyfill(translationData?.locale || "en");
    messages = translationData?.messages?.reduce((all, current) => {
      all[current.key] = current.value;
      return all;
    }, {});
  } else {
    messages = await loadMessages(locale?.toLowerCase() || "en");
  }

  render(messages);
};

init();
