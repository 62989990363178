import { Box, SystemProps } from "@storyofams/react-ui";
import { pick } from "@styled-system/props";
import Imgix from "react-imgix";
import styled, { css } from "styled-components";
import { maxHeight } from "styled-system";

import { FileUnion, UrlFile } from "~/graphql/api/sdk";
import { useBlurhash } from "~/hooks";
import { getFileUrl } from "~/lib";

interface MediaProps {
  cover?: boolean;
  image?: FileUnion | null;
  video?: UrlFile | null;
  fullWidth?: boolean;
  alt: string;
  sizes?: string;
}

const ImageWrapper = styled(Box)<{
  cover?: boolean;
  hasBlurhash?: boolean;
  fullWidth?: boolean;
  placeholderWidth?: number;
}>`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${(p) =>
    !p.cover &&
    css`
      border-radius: 8px;
    `}

  ${(p) =>
    p.cover &&
    css`
      height: 100vh;
    `}

  ${(p) =>
    p.hasBlurhash
      ? css`
          img {
            position: absolute;
            top: 0;

            ${p.fullWidth
              ? css`
                  left: 0;
                  width: 100%;
                `
              : css`
                  left: 50%;
                  transform: translateX(-50%);
                `}
            display: block;
            height: 100%;
            object-fit: cover;
            object-position: center;

            ${!p.cover &&
            css`
              border-radius: 8px;
            `}
          }
        `
      : p.cover
      ? css`
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        `
      : css`
          border-radius: 8px;
          overflow: hidden;

          img {
            margin: 0 auto;
            height: auto;
            max-height: 100%;
            border-radius: 8px;
            overflow: hidden;
            ${maxHeight}
          }
        `}

  .ls-blur-up-is-loading {
    visibility: hidden;
  }

  .original {
    opacity: 1;
    transition: opacity 250ms linear;

    &.lazyloaded {
      opacity: 1;
    }
  }

  .ls-blur-up-img {
    opacity: 1;
    transition: opacity 500ms linear;
    width: ${(p) => (p.placeholderWidth ? `${p.placeholderWidth}px` : "100%")};

    &.ls-original-loaded {
      opacity: 1;
    }
  }
`;

const VideoWrapper = styled(Box)<{ cover?: boolean; fullWidth?: boolean }>`
  width: 100%;
  position: relative;
  overflow: hidden;

  > video {
    position: absolute;
    top: 0;

    ${(p) =>
      p.fullWidth
        ? css`
            left: 0;
            width: 100%;
          `
        : css`
            left: 50%;
            transform: translateX(-50%);
          `}
  }

  ${(p) =>
    p.cover
      ? css`
          > video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        `
      : css`
          border-radius: 8px;
          overflow: hidden;

          > video {
            height: auto;
            max-height: 100%;
            border-radius: 8px;
            overflow: hidden;
          }
        `}
`;

export const Media = ({ cover, image, video, alt = "", fullWidth, sizes, ...props }: MediaProps & SystemProps) => {
  const imageSrc = image ? getFileUrl(image) : undefined;
  const blurhashSrc = useBlurhash(!!image && "url" in image ? image.blurhash : undefined);
  const hasBlurhash = !!image && "url" in image && !!image.blurhash;

  return (
    <>
      {video ? (
        <VideoWrapper cover={cover} fullWidth={fullWidth} className="video-wrapper" {...pick(props)}>
          {video?.height && video?.width && <Box display={cover ? ["block", "none"] : "block"} aria-hidden pb={`${(video.height / video.width) * 100}%`} />}

          <video preload="auto" loop autoPlay muted playsInline controls={false}>
            <source type="video/mp4" src={video.url} />
          </video>
        </VideoWrapper>
      ) : (
        <ImageWrapper
          cover={cover}
          hasBlurhash={hasBlurhash}
          className="image-wrapper"
          fullWidth={fullWidth}
          placeholderWidth={
            !fullWidth && !!image && "url" in image && !image.url?.includes("giphy.com") && image?.height && image?.width
              ? Math.max(Math.min(image.height, parseInt(props.minHeight, 10) || Infinity), parseInt(props.maxHeight, 10) || 0) / (image.height / image.width)
              : undefined
          }
          {...pick(props)}
        >
          {!!image && "url" in image && !image.url?.includes("giphy.com") && image?.height && image?.width && (
            <Box aria-hidden pb={`${(image.height / image.width) * 100}%`} />
          )}

          {(!hasBlurhash || !!blurhashSrc) && !!imageSrc && (
            <>
              {(process.env.NODE_ENV === "development" && (!image || !("url" in image))) || (image && "url" in image && image.url?.includes("giphy.com")) ? (
                <img src={imageSrc} alt={alt ?? "decorative image"} />
              ) : (
                <Box width="100%" height="100%">
                  <Imgix
                    className={`${hasBlurhash ? " original" : ""}`}
                    src={imageSrc}
                    sizes={sizes}
                    disableSrcSet={true} // Forces Imgix to output a src attribute
                    attributeConfig={{
                      src: "src", // Ensure src is always set
                      srcSet: "srcSet", // Allow srcset but ensure src exists
                      sizes: "sizes",
                    }}
                    htmlAttributes={{
                      alt: alt ?? "decorative image",
                      width: (image as any)?.width ?? "100%",
                      height: (image as any)?.height ?? "100%",
                      loading: "lazy",
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </ImageWrapper>
      )}
    </>
  );
};
